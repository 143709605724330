import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  FormInput,
  FormSelect,
  Row,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";
import Orders from "./Orders";
import Stocks from "./Stocks";
import OrderForm from "./OrderForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {
  const {
    user,
    getRemainingStocks,
    getStocks,
    getItemList,
    MinScreenWidth,
    setOpenOrderForm,
  } = useAppData();

  useEffect(() => {
    getRemainingStocks();
    getStocks();
    getItemList();
  }, [user]);

  return (
    <Row style={{ padding: 10 }}>
      <Col md={12} style={{ padding: 0 }}>
        {MinScreenWidth && (
          <Button block onClick={() => setOpenOrderForm(true)}>
            <FontAwesomeIcon icon={faPlus} /> New Order
          </Button>
        )}
        <OrderForm />
      </Col>
      <Col md={12} style={{ padding: 0 }}>
        <Row style={{ marginTop: MinScreenWidth ? 20 : 30 }}>
          <Col md={12} style={{ padding: MinScreenWidth ? 0 : 10 }}>
            <Orders />
          </Col>
          <Col
            md={12}
            style={{
              padding: MinScreenWidth ? 0 : 10,
              marginTop: MinScreenWidth ? 20 : 0,
            }}
          >
            <Stocks />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
