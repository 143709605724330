import React, { useEffect, useState } from "react";
import {
  Button,
  ModalFooter,
  Col,
  FormGroup,
  FormInput,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";

const OrderForm = () => {
  const {
    user,
    stocks,
    addOrder,
    items,
    openOrderForm,
    setOpenOrderForm,
    remainingStocks,
    MinScreenWidth,
  } = useAppData();

  const [status, setStatus] = useState("");

  const newForm = {
    id: (Math.random() + 2).toString(36).substring(7),
    variant: "",
    price: 0,
    quantity: 0,
    serials: [],
    subTotal: 0,
  };

  const [n_orders, setNOrders] = useState([newForm]);

  const [storeNo, setStoreNo] = useState("");
  const [isCompleteOrders, setCompleteOrders] = useState(false);

  const [sending, setSending] = useState(false);

  return (
    <Modal size="lg" open={openOrderForm} toggle={() => ""}>
      <ModalHeader>Order Form</ModalHeader>
      <ModalBody style={{ overflow: "auto", padding: 10 }}>
        {stocks.length === 0 ? (
          <p style={{ textAlign: "center", padding: 25 }}>Loading...</p>
        ) : (
          <>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <label>Store/ Client Number</label>
                  <FormInput
                    value={storeNo}
                    onChange={(e) => setStoreNo(e.target.value)}
                    placeholder="please input the store/ client number"
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            {storeNo !== "" && (
              <>
                <Row>
                  <Col md={3} xs={8} sm={8}>
                    ITEM
                  </Col>
                  <Col md={2} xs={4} sm={4}>
                    QTY
                  </Col>
                  {!MinScreenWidth && (
                    <>
                      <Col md={6}>SERIALS</Col>
                      <Col md={1}></Col>
                    </>
                  )}
                </Row>
                {n_orders.map((order, index) => (
                  <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Col md={3} xs={8} sm={8}>
                      <FormSelect
                        value={order.variant}
                        size="sm"
                        onChange={(e) =>
                          setNOrders(
                            n_orders.map((o) =>
                              o.id === order.id
                                ? {
                                    ...o,
                                    variant: e.target.value,
                                    serials: stocks.filter(
                                      (stock) => stock.variant === order.variant
                                    )
                                      ? stocks
                                          .filter(
                                            (stock) =>
                                              stock.variant === order.variant
                                          )
                                          .map((stock) => stock.serial)
                                          .slice(0, order.quantity)
                                      : "",
                                  }
                                : o
                            )
                          )
                        }
                      >
                        <option value="">SELECT ONE</option>
                        {items.map((item) => (
                          <option
                            value={item}
                            disabled={n_orders
                              .map((order) => order.variant)
                              .includes(item)}
                            style={{
                              backgroundColor: n_orders
                                .map((order) => order.variant)
                                .includes(item)
                                ? "gray"
                                : "#fff",
                            }}
                          >
                            {item}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>
                    <Col md={2} xs={4} sm={4}>
                      <InputGroup>
                        <FormInput
                          value={order.quantity}
                          size="sm"
                          type="number"
                          onChange={(e) =>
                            setNOrders(
                              n_orders.map((o) =>
                                o.id === order.id
                                  ? {
                                      ...o,
                                      quantity:
                                        e.target.value === ""
                                          ? 0
                                          : remainingStocks.find(
                                              (stock) =>
                                                order.variant === stock.variant
                                            ).qty < e.target.value
                                          ? remainingStocks.find(
                                              (stock) =>
                                                order.variant === stock.variant
                                            ).qty
                                          : parseFloat(e.target.value),
                                      price: parseFloat(
                                        stocks.filter(
                                          (stock) =>
                                            stock.variant === order.variant
                                        )[0].price
                                      ),
                                      subTotal:
                                        parseFloat(
                                          stocks.filter(
                                            (stock) =>
                                              stock.variant === order.variant
                                          )[0].price
                                        ) * e.target.value,
                                      serials: stocks.filter(
                                        (stock) =>
                                          stock.variant === order.variant
                                      )
                                        ? stocks
                                            .filter(
                                              (stock) =>
                                                stock.variant === order.variant
                                            )
                                            .map((stock) => stock.serial)
                                            .slice(0, e.target.value)
                                        : "",
                                    }
                                  : o
                              )
                            )
                          }
                          invalid={
                            order.variant &&
                            remainingStocks.find(
                              (stock) => order.variant === stock.variant
                            ).qty < order.quantity
                          }
                        />
                        {order.variant && (
                          <InputGroupAddon type="append">
                            <InputGroupText>
                              {
                                remainingStocks.find(
                                  (stock) => order.variant === stock.variant
                                ).qty
                              }
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </Col>
                    <Col md={6} xs={8} sm={8}>
                      {order.serials.length !== 0
                        ? order.serials.join(", ")
                        : "Please select the item and quantity"}
                    </Col>
                    <Col md={1} xs={4} sm={4}>
                      {index !== 0 || n_orders.length > 1 ? (
                        <Button
                          size="sm"
                          theme="danger"
                          onClick={() =>
                            setNOrders(
                              n_orders.filter((o) => o.id !== order.id)
                            )
                          }
                          style={{ marginTop: 3 }}
                          block={MinScreenWidth}
                        >
                          X
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                ))}
                {n_orders[n_orders.length - 1].quantity !== 0 ? (
                  <Row>
                    <Col>
                      <Button
                        size="sm"
                        onClick={() => setNOrders([...n_orders, newForm])}
                        theme="success"
                      >
                        ADD NEW ORDER
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter>
        {n_orders[n_orders.length - 1].quantity !== 0 && (
          <FormCheckbox
            onChange={() => setCompleteOrders(!isCompleteOrders)}
            checked={isCompleteOrders}
          >
            I declare that all order details is correct and complete.
          </FormCheckbox>
        )}
        {n_orders[n_orders.length - 1].quantity !== 0 && isCompleteOrders ? (
          <>
            <Button
              onClick={() =>
                addOrder(
                  { ...user, orders: n_orders, storeNo: storeNo },
                  setStatus
                ) || setSending(true)
              }
              theme="info"
              style={{ marginRight: 10 }}
              size="sm"
              disabled={sending}
            >
              {sending ? "Please wait ..." : "Send Orders"}
            </Button>
          </>
        ) : (
          ""
        )}
        <Button
          theme="secondary"
          onClick={() =>
            setStoreNo("") || setNOrders([newForm]) || setOpenOrderForm(false)
          }
          size="sm"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderForm;
