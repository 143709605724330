import React from "react";
import { Col, ListGroup, ListGroupItem, Row } from "shards-react";
import dateFormat from "dateformat";
import backgroundImage from "../../assets/background.png";
import Logo from "../../assets/logo.png";

const PrintView = ({ order }) => {
  return (
    <>
      <div
        style={{
          padding: "20px",
          height: "100vh",
          // backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <Row>
          <Col md={2}>
            <img src={Logo} style={{ width: "80%" }} />
          </Col>
          <Col md={10} style={{ textAlign: "center", paddingTop: 40 }}>
            <h2 style={{ fontWeight: "800" }}>ORDERING FORM</h2>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20, marginTop: 20 , fontWeight: "700"}}>
          <Col md={6} style={{ marginBottom: 10 }}>
            Store Number: <b>{order.store_number.replace("*", "")}</b>
          </Col>
          <Col md={6} style={{ textAlign: "right" }}>
            {dateFormat(new Date(order.timestamp), "fullDate")}
          </Col>
          <Col md={12} style={{ marginBottom: 10 }}>
            Store Name: <b>{order.store_name}</b>
          </Col>
          <Col md={12} style={{ marginBottom: 10 }}>
            Store Addr.: <b>{order.full_address}</b>
          </Col>
          <Col md={12}>
            Store Manager: {order.store_manager}
          </Col>
        </Row>
        <ListGroup>
          <ListGroupItem>
            <Row style={{ fontWeight: "900" , fontSize: "20px"}}>
              <Col md={4}>ITEM NAME</Col>
              <Col md={2}>AMOUNT</Col>
              <Col md={1}>QTY</Col>
              <Col md={3}>SERIALS</Col>
              <Col md={2}>SUBTOTAL</Col>
            </Row>
          </ListGroupItem>
          {order.orders.map((o) => (
            <ListGroupItem  style={{ fontWeight: "700" }}>
              <Row>
                <Col md={4}>{o.variant}</Col>
                <Col md={2}>{o.price}</Col>
                <Col md={1}>{o.quantity}</Col>
                <Col md={3}>{o.serials.join(", ")}</Col>
                <Col md={2}>Php {o.subTotal}</Col>
              </Row>
            </ListGroupItem>
          ))}
          <ListGroupItem>
            <Row style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
              <Col md={10}>TOTAL</Col>
              <Col md={2}>
                Php {order.orders.reduce((a, b) => a + b.subTotal, 0)}
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem>
            <Row>
              <Col style={{ textAlign: "center" }}>
                ------------------------------------- NOTHING FOLLOWS
                ----------------------------------
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>

        <Row style={{ marginTop: 100 }}>
          <Col md={6} style={{ fontWeight: 600 }}>
            Received By:__________________________________
          </Col>
          <Col md={6} style={{ textAlign: "center", fontWeight: 500 }}>
            Prepared by Jobelyn Ocampo
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PrintView;
