import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  FormCheckbox,
  FormGroup,
  FormInput,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";
import PrintView from "../../components/Basic/PrintView";
import ReactToPrint from "react-to-print";
import ReceiptView from "../Basic/ReceiptView";
import { toPng } from "html-to-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";

const Orders = () => {
  const { MinScreenWidth, user, getOrders, orders, downloadOrderLists } =
    useAppData();

  useEffect(() => {
    getOrders();
  }, [user]);

  console.log(orders);

  const [selectedID, setSelectedID] = useState("");
  const componentRef = useRef();

  const [printViewOption, setPrintViewOption] = useState(true);

  const onButtonClick = () => {
    if (componentRef !== null) {
      toPng(componentRef.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${selectedID}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log("err");
        });
    }
  };

  const [searchOrder, setSearchOrder] = useState("");

  return (
    <>
      {orders.length !== 0 ? (
        <div>
          {orders.find((order) => order.id === selectedID) && (
            <Modal
              open={orders.find((order) => order.id === selectedID)}
              toggle={() => setSelectedID("")}
              size="lg"
            >
              <ModalHeader style={{ display: "block" }}>
                <Row>
                  <Col md={4}>
                    {printViewOption ? (
                      <>
                        {/* <ReactToPrint
                          trigger={() => (
                            <Button size="sm" theme="info">
                              <FontAwesomeIcon icon={faPrint} /> Print
                            </Button>
                          )}
                          content={() => componentRef.current}
                        /> */}
                        <Button
                          size="sm"
                          theme="success"
                          onClick={() => onButtonClick()}
                          style={{ marginLeft: 5 }}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download
                        </Button>
                      </>
                    ) : (
                      <>
                        <ReactToPrint
                          trigger={() => (
                            <Button size="sm" theme="info">
                              Save AS PDF
                            </Button>
                          )}
                          content={() => componentRef.current}
                        />
                        {/* <Button
                          size="sm"
                          theme="success"
                          onClick={() => onButtonClick()}
                          style={{ marginLeft: 5 }}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download As
                          Image
                        </Button> */}
                      </>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormCheckbox
                      toggle
                      small
                      checked={printViewOption}
                      onChange={() => setPrintViewOption(!printViewOption)}
                    >
                      Switch to Reciept View Print
                    </FormCheckbox>
                  </Col>
                  <Col md={2}>
                    <Button
                      size="sm"
                      theme="danger"
                      block
                      onClick={() => setSelectedID("")}
                    >
                      {" "}
                      Close
                    </Button>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody style={{ overflow: "auto", padding: 0 }}>
                <div
                  ref={componentRef}
                  style={{
                    width: printViewOption ? "300px" : "100%",
                    backgroundColor: "#ffff",
                  }}
                >
                  {printViewOption ? (
                    <ReceiptView
                      order={orders.find((order) => order.id === selectedID)}
                    />
                  ) : (
                    <PrintView
                      order={orders.find((order) => order.id === selectedID)}
                    />
                  )}
                </div>
              </ModalBody>
            </Modal>
          )}

          <Card>
            <CardHeader>Today's Orders</CardHeader>
            <CardBody style={{ padding: 5 }}>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <FormInput
                      value={searchOrder}
                      type="number"
                      onChange={(e) => setSearchOrder(e.target.value)}
                      placeholder="store number/client id"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}></Col>
                <Col md={4} style={{textAlign: "right"}}>
                  <Button
                    onClick={() =>
                      downloadOrderLists(
                        orders.filter((order) =>
                          searchOrder !== ""
                            ? order.store_number.includes(searchOrder)
                            : order
                        )
                      )
                    }
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </CardBody>
            <CardBody style={{ padding: 0 }}>
              <ListGroup>
                {!MinScreenWidth && (
                  <ListGroupItem style={{ padding: 10, fontWeight: 500 }}>
                    <Row>
                      <Col md={2} xs={6} sm={6}>
                        Store #
                      </Col>
                      <Col md={4} xs={6} sm={6}>
                        Store Name
                      </Col>
                      <Col md={3} xs={6} sm={6}>
                        Amount
                      </Col>
                      <Col md={2} xs={6} sm={6}>
                        Action
                      </Col>
                    </Row>
                  </ListGroupItem>
                )}

                {orders
                  .filter((order) =>
                    searchOrder !== ""
                      ? order.store_number.includes(searchOrder)
                      : order
                  )
                  .map((order, index) => (
                    <ListGroupItem style={{ padding: 10 }}>
                      <Row key={index}>
                        <Col md={2} xs={3} sm={3}>
                          {order.store_number.replace("*", "")}
                        </Col>
                        <Col md={4} xs={9} sm={9}>
                          {order.store_name}
                        </Col>
                        <Col md={3} xs={9} sm={9}>
                          Php {order.orders.reduce((a, b) => a + b.subTotal, 0)}
                        </Col>
                        <Col md={2} xs={3} sm={3}>
                          <Button
                            size="sm"
                            onClick={() => setSelectedID(order.id)}
                            block={MinScreenWidth}
                          >
                            Print
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </CardBody>
          </Card>
        </div>
      ) : (
        <Card>
          <CardBody> No Orders</CardBody>
        </Card>
      )}
    </>
  );
};

export default Orders;
