import React, { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormCheckbox,
  ListGroup,
  ListGroupItem,
  Row,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";

const DRUpload = () => {
  const { user, uploadSerials, downloadSerialList } = useAppData();

  console.log(user);
  const [uploadedFile, setUploadedFile] = useState([]);

  const [uploadedData, setUploadedData] = useState([]);

  const [serials, setSerials] = useState([]);

  const generateSerials = (data) => {
    const sequence = (data) => {
      const list = [];
      for (let index = 0; index < data.quantity; index++) {
        list.push(data.serials[0]++);
      }

      return list;
    };

    let list = data
      .map((d) => ({
        quantity: parseFloat(d[0]),
        variant: d[1],
        serials: d[2],
        price: parseFloat(d[3]),
        isSequence: d[4] === "yes",
        dr_number: d[5],
        user: user.username,
      }))
      .map((d) => ({
        ...d,
        serials: d.isSequence
          ? sequence({ ...d, serials: d.serials.split("-") })
          : d.serials.split(", "),
      }));

    return list;
  };

  const createSerialList = (data) => {
    let list = data
      .map((d) => ({
        serials: d.serials.map((s) => ({
          variant: d.variant,
          serial: parseInt(s),
          price: d.price,
          user: d.user,
          dr_number: d.dr_number,
          status: "available",
        })),
      }))
      .map((d) => d.serials);
    console.log(list);

    let finalList = [];

    for (let a = 0; a < list.length; a++) {
      for (let b = 0; b < list[a].length; b++) {
        finalList.push(list[a][b]);
      }
    }

    return finalList;
  };

  useEffect(() => {
    if (uploadedData.length !== 0) {
      setSerials(createSerialList(uploadedData));
    }
  }, [uploadedData]);

  const [verifyUpload, setVerifyUpload] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(user.length === 0){
  //       window.location.replace("/")
  //     }
  //   }, 3000);
  // }, [user])

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>Upload DR file here</CardHeader>
          <CardBody>
            <CSVReader
              onFileLoaded={(data, fileInfo) => {
                setUploadedFile(data.slice(1, data.length - 1));
              }}
            />
            {uploadedFile.length !== 0 && (
              <Button
                onClick={() => setUploadedData(generateSerials(uploadedFile))}
                style={{ marginTop: 10 }}
                size="sm"
              >
                Generate Serials
              </Button>
            )}
          </CardBody>
        </Card>
        {uploadedData.length !== 0 && (
          <>
            <Card style={{ marginTop: 20 }}>
              <CardHeader>Uploaded Data</CardHeader>
              <CardBody>
                <ListGroup>
                  <ListGroupItem style={{ padding: 5, fontWeight: 600 }}>
                    <Row>
                      <Col md={4}>Item Name</Col>
                      <Col md={2}>Qty</Col>
                      <Col md={2}>Price</Col>
                      <Col md={3}></Col>
                    </Row>
                  </ListGroupItem>
                  {uploadedData.map((data) => (
                    <ListGroupItem style={{ padding: 5 }}>
                      <Row>
                        <Col md={4}>{data.variant}</Col>
                        <Col md={2}>{data.quantity}</Col>
                        <Col md={2}>{data.price}</Col>
                        <Col md={3}></Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
            <Card style={{marginTop: 20}}>
              <CardBody>
                <FormCheckbox checked={verifyUpload} onChange={() => setVerifyUpload(!verifyUpload)}>I verify the upload count of serials is true and correct.</FormCheckbox>
                {
                    verifyUpload && <Button
                    onClick={() =>
                      uploadSerials({ serials, user }, () => "success")
                    }
                  >
                    Upload Serials
                  </Button>
                }
                
              </CardBody>
            </Card>
          </>
        )}
      </Col>
    </Row>
  );
};

export default DRUpload;
