import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormInput,
  Row,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";

const Login = () => {
  const { login, user } = useAppData();
  const [form, setForm] = useState({ username: "", password: "" });

  useEffect(() => {
    if (user.length !== 0) {
      window.location.replace("/");
    }
  }, [user]);
  return (
    <div style={{ padding: "10%" }}>
      <Row>
        <Col md={4}></Col>
        <Col md={4}>
          <Card style={{ margin: "0 auto" }}>
            <CardBody>
              <FormGroup>
                <label>USERNAME</label>
                <FormInput
                  placeholder="username"
                  value={form.username}
                  onChange={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup>
                <label>PASSWORD</label>
                <FormInput
                  placeholder="password"
                  type="password"
                  value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </FormGroup>
              <Button onClick={() => login(form)}>Login</Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}></Col>
      </Row>
    </div>
  );
};

export default Login;
