import React from "react";
import { Router } from "@reach/router";
import OrderForm from "./components/Pages/OrderForm";
import { useAppData } from "./hooks/context/Appcontext";
import Login from "./components/Pages/Login";
import Dashboard from "./components/Pages/Dashboard";
import Header from "./components/Basic/Header";
import DRUpload from "./components/Pages/DRUpload";

const App = () => {
  const { user } = useAppData();

  return (
    <div>
      {user.length !== 0 && <Header />}

      <Router>
        {user.length !== 0 ? <Dashboard path="/" /> : <Login path="/" />}
        <Login path="/login" />
        <DRUpload path="/dr-upload"/>
      </Router>
    </div>
  );
};

export default App;
