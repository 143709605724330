import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../config";

function use711Orders() {
  const [user, setUSer] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [orders, setOrders] = useState([]);
  const [items, setItems] = useState([]);
  const [remainingStocks, setRemainingStocks] = useState([]);

  function login(data) {
    console.log(data);
    axios
      .post(`${API_URL}/login`, {
        username: data.username,
        password: data.password,
      })
      .then((res) => {
        if (res.data.length !== 0) {
          window.localStorage.setItem(
            "session711",
            JSON.stringify(res.data[0])
          );
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
  }

  function getLoginSession() {
    setUSer(
      window.localStorage.getItem("session711")
        ? JSON.parse(window.localStorage.getItem("session711"))
        : []
    );
  }

  function getStocks() {
    axios
      .post(`${API_URL}/get_stocks`, {
        username: user.username,
        password: user.password,
        otp: user.otp,
      })
      .then((res) => {
        if (res.data) {
          console.log(res);
          setStocks(res.data);
        }
      });
  }

  function getRemainingStocks() {
    axios
      .post(`${API_URL}/get_remaining_stocks`, {
        username: user.username,
        password: user.password,
        otp: user.otp,
      })
      .then((res) => {
        if (res.data) {
          console.log(res);
          setRemainingStocks(res.data);
        }
      });
  }

  function getItemList() {
    axios
      .post(`${API_URL}/get_itemList`, {
        username: user.username,
        password: user.password,
        otp: user.otp,
      })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setItems(res.data.map((d) => d.variant));
        }
      });
  }

  function getOrders() {
    axios
      .post(`${API_URL}/get_orders`, {
        username: user.username,
        password: user.password,
        otp: user.otp,
      })
      .then((res) => {
        console.log(res);
        if (parseInt(res.data.length) !== 0) {
          setOrders(
            res.data.map((d) => ({ ...d, orders: JSON.parse(d.orders) }))
          );
        }
      });
  }

  function addOrder(data, action) {
    function getSerials() {
      let list = [];
      for (let i = 0; i < data.orders.length; i++) {
        list.push(...data.orders[i].serials);
      }
      return list;
    }

    axios
      .post(`${API_URL}/insert_order`, {
        username: data.username,
        password: data.password,
        otp: user.otp,
        storeNo: `*${data.storeNo}`,
        orders: JSON.stringify(data.orders).replace(/'/g, ""),
        serials: getSerials(),
      })
      .then((res) => {
        console.log(res) || action(res.data.status) || window.location.reload();
      });
  }

  function uploadSerials(data, action) {
    axios
      .post(`${API_URL}/upload_serials`, {
        username: data.username,
        password: data.password,
        otp: data.otp,
        serials: data.serials,
      })
      .then((res) => {
        action(res);
        // console.log(res);
        window.location.replace("/");
      });
  }

  useEffect(() => {
    getLoginSession();
  }, []);

  return {
    uploadSerials,
    user,
    login,
    stocks,
    getStocks,
    addOrder,
    orders,
    getOrders,
    items,
    getItemList,
    getRemainingStocks,
    remainingStocks,
  };
}

export default use711Orders;
