import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormInput,
  ListGroup,
  ListGroupItem,
  Row,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";

const Stocks = () => {
  const { remainingStocks, stocks, downloadRemainingStocks } = useAppData();
  const [search, setSearch] = useState("");

  return (
    <Card>
      <CardHeader>Remaining Stocks</CardHeader>
      <CardBody style={{ padding: 10 }}>
        <Row>
          <Col md={4}>
            <FormInput
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col md={4}></Col>
          <Col md={4} style={{textAlign: "right"}}>
            <Button
              onClick={() =>
                downloadRemainingStocks(
                  remainingStocks
                    .filter((stock) =>
                      stock.variant
                        .toLowerCase()
                        .includes(search.toLocaleLowerCase())
                    )
                    .map((stock) => ({
                      ...stock,
                      serials: stocks
                        .filter((s) => s.variant === stock.variant)
                        .map((s) => s.serial)
                        .join("|"),
                    }))
                )
              }
            >
              Download
            </Button>
          </Col>
        </Row>
      </CardBody>
      <CardBody style={{ padding: 0 }}>
        <ListGroup>
          {remainingStocks.filter((stock) =>
            stock.variant.toLowerCase().includes(search.toLocaleLowerCase())
          ).length !== 0 ? (
            remainingStocks

              .filter((stock) =>
                stock.variant.toLowerCase().includes(search.toLocaleLowerCase())
              )
              .map((stock) => ({
                ...stock,
                serials: stocks
                  .filter((s) => s.variant === stock.variant)
                  .map((s) => s.serial)
                  .join("| "),
              }))
              .map((stock) => (
                <ListGroupItem>
                  <Row>
                    <Col md={4} xs={8} sm={8}>
                      <strong>{stock.variant}</strong>
                    </Col>
                    <Col md={2} xs={4} sm={4}>
                      <strong>{stock.qty} pcs</strong>
                    </Col>
                    <Col md={12} xs={4} sm={4}>
                      <div className="serial-container">{stock.serials}</div>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))
          ) : (
            <ListGroupItem>No Remaining stocks on your inventory</ListGroupItem>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default Stocks;
