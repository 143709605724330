import React, { createContext, useContext, useEffect, useState } from "react";
import use711Orders from "../api/use711Orders";
import useMediaQuery from "use-mediaquery";
import useExtractData from "../api/useExtractData";

const Context = createContext();

export function useAppData() {
  return useContext(Context);
}

export const AppContext = ({ children }) => {
  const {
    user,
    login,
    stocks,
    getStocks,
    addOrder,
    orders,
    getOrders,
    items,
    getItemList,
    getRemainingStocks,
    remainingStocks,
    uploadSerials
  } = use711Orders();

  const { downloadRemainingStocks, downloadOrderLists, downloadSerialList} = useExtractData()

  const [openOrderForm, setOpenOrderForm] = useState(false);
  const MinScreenWidth = useMediaQuery("(max-width: 800px)");

  return (
    <Context.Provider
      value={{
        uploadSerials,
        downloadRemainingStocks,
        downloadOrderLists,
        downloadSerialList,
        user,
        login,
        stocks,
        getStocks,
        addOrder,
        orders,
        getOrders,
        openOrderForm,
        setOpenOrderForm,
        items,
        getItemList,
        getRemainingStocks,
        remainingStocks,
        MinScreenWidth
      }}
    >
      {children}
    </Context.Provider>
  );
};
