import React from "react";
import { Col, ListGroup, ListGroupItem, Row } from "shards-react";
import dateFormat from "dateformat";
import backgroundImage from "../../assets/background.png";
import Logo from "../../assets/logo.png";
import { useAppData } from "../../hooks/context/Appcontext";

const ReceiptView = ({ order }) => {
  const { MinScreenWidth } = useAppData();

  return (
    <div
      style={{
        backgroundColor: "#fff",
        fontWeight: 600,
      }}
    >
      <Row>
        <Col md={12} style={{ textAlign: "center" }}>
          <img src={Logo} style={{ width: "25%" }} />
        </Col>
        <Col md={12} style={{ textAlign: "center", paddingTop: 0 }}>
          <p style={{ fontWeight: "800" }}>
            ORDERING RECEIPT
            <br />
            #14 Balete Drv., PBE Bldg., Brgy. Kaunlaran, Quezon City
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 20, marginTop: 20, fontSize: 18, textAlign: "center" }}>
        <Col md={12}>{dateFormat(new Date(order.timestamp), "fullDate")}</Col>
        <Col md={12} style={{ marginBottom: 5 }}>
          Store Number: <b>{order.store_number.replace("*", "")}</b>
        </Col>
        <Col md={12} style={{ marginBottom: 10 }}>
          <b>{order.store_name}</b>
        </Col>
        <Col md={12} style={{ marginBottom: 10 }}>
          <b>{order.full_address}</b>
        </Col>
        <Col md={12}>Store Manager: {order.store_manager}</Col>
      </Row>
      

      <ListGroup className="receipt-items">
        <ListGroupItem style={{textAlign:"center"}}>ORDER DETAILS</ListGroupItem>
        {order.orders.map((o) => (
          <ListGroupItem>
            <Row>
              <Col md={12} style={{ padding: 0, fontSize: 18 }}>
                {o.variant}
              </Col>
              <Col md={12} style={{ padding: 0, textAlign: "right" }}></Col>
              <Col md={12} style={{ padding: 0, fontSize: 20 }}>
                {o.serials.join(", ")}
              </Col>
              <Col
                md={12}
                style={{
                  fontWeight: 800,
                  padding: 0,
                  textAlign: "right",
                  fontSize: 20,
                }}
              >
                P{o.price} x {o.quantity} pcs = P {o.subTotal}
              </Col>
            </Row>
          </ListGroupItem>
        ))}
        <ListGroupItem>
          <Row style={{ fontWeight: "bold", fontSize: 20 }}>
            <Col md={8} style={{ padding: 0 }}>
              TOTAL
            </Col>
            <Col md={4} style={{ padding: 0, textAlign: "right" }}>
              P {order.orders.reduce((a, b) => a + b.subTotal, 0)}
            </Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem>
          <Row>
            <Col style={{ textAlign: "center" }}>
              ----- NOTHING FOLLOWS -------
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>

      <Row style={{ marginTop: 150, fontSize: 18 }}>
        <Col md={12} style={{ fontWeight: 600 }}>
          Received By:__________________
        </Col>
        <Col
          md={12}
          style={{
            textAlign: "left",
            fontWeight: 500,
            marginTop: 20,
          }}
        >
          Prepared by Jobelyn Ocampo
        </Col>
      </Row>
    </div>
  );
};

export default ReceiptView;
