import dateFormat from "dateformat";
import useConvertToCSV from "./useConvertToCSV";

function useExtractData() {
  const { exportCSVFile } = useConvertToCSV();

  function downloadRemainingStocks(stocks) {
    var headers = {
      variant: "VARIANT",
      qty: "Qty",
      serials: "SERIALS",
    };

    var itemsNotFormatted = stocks;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant: item.variant,
        qty: item.qty,
        serials: item.serials,
      });
    });

    var fileTitle = `Trade_Check_Remaining_Stocks${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadOrderLists(orders) {
    var itemsNotFormatted = orders;

    var itemsFormatted = [];

    var headers = {
      store_number: "Store #",
      store_name: "Store Name",
      order: "Amont",
      variant: "Variant",
      serials: "Serials",
    };

    // format the data
    itemsNotFormatted.forEach((item) => {
      item.orders.forEach((order) => {
        itemsFormatted.push({
          store_number: item.store_number,
          store_name: item.store_name,
          order: item.orders.reduce((a, b) => a + b.subTotal, 0),
          variant: order.variant,
          serials: order.serials.join("|"),
        });
      });
    });

    console.log(itemsFormatted);

    var fileTitle = `Trade_Check_Orders${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  function downloadSerialList(serials) {
    var headers = {
      variant: "variant",
      price: "price",
      status: "status",
      user: "user",
      dr_number: "dr_number",
      serial: "serial",
    };

    var itemsNotFormatted = serials;

    var itemsFormatted = [];

    // format the data
    itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
        variant: item.variant,
        price: item.price,
        status: item.status,
        user: item.user,
        dr_number: item.dr_number,
        serial: item.serial,
      });
    });

    var fileTitle = `SERIALS_FOR_UPLOAD_${dateFormat()}`; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
  }

  return { downloadRemainingStocks, downloadOrderLists, downloadSerialList };
}

export default useExtractData;
