import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppContext } from './hooks/context/Appcontext';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppContext>
    <App />
  </AppContext>
);
