import { faPlus, faSignOut, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from "shards-react";
import { useAppData } from "../../hooks/context/Appcontext";

const Header = () => {
  const { openOrderForm, setOpenOrderForm } = useAppData();
  const [collapseOpen, setCollapseOpen] = useState(false);
  return (
    <Navbar type="dark" theme="primary" expand="md">
      <NavbarBrand href="/">Gate Ordering App</NavbarBrand>
      {/* <NavbarToggler onClick={() => setCollapseOpen(!collapseOpen)} /> */}

      <Collapse open={collapseOpen} navbar>
        <Nav navbar>
          <NavItem>
            <NavLink active href="#" onClick={() => setOpenOrderForm(true)}>
              <FontAwesomeIcon icon={faPlus} /> New Order
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink active href="/dr-upload">
              <FontAwesomeIcon icon={faUpload} /> Upload DR
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>

      <Nav navbar className="ml-auto">
        <NavItem>
          <NavLink active href="#" onClick={() => window.localStorage.removeItem("session711") || window.location.replace("/")}>
            <FontAwesomeIcon icon={faSignOut} /> Logout
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
